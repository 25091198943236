import {
    Box,
    makeStyles,
    Typography,
    FormHelperText,
    Button, MenuItem
} from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ContentTwoSided, LeftSide, RightSide } from "../core/ContentTwoSided";
import CotroliaFormControl from "../core/input/CotroliaFormControl";
import CotroliaInputLabel from "../core/input/CotroliaInputLabel";
import CotroliaSelect from "../core/input/CotroliaSelect";
import CotroliaTextField from "../core/input/CotroliaTextField";
import { CotroliaStepper, CotroliaStepperActions } from "../core/stepper";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import CotroliaAutocompleteCustom from "../core/input/CotroliaAutocompleteCustom";
import configurationService from "../../services/configuration.service";

const useStyle = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
  },
  titleDesc: {
    color: theme.palette.text.secondary,
  },
  uploadContainer: {
    padding: `${theme.spacing(1.5)}px 0px 0px`,
  },
  mt: {
    marginTop: theme.spacing(2),
  },
}));

const emptyError = {
  error: false,
  msg: "",
};

const NewInterventionStepCar = ({
  values,
  updateValue,
  steps,
  activeStep,
  nextStep,
  previousStep,
  configuration,
}) => {
  const { t } = useTranslation();
  const classes = useStyle();
  const [carType, setCarType] = useState();
  const [carTypeError, setCarTypeError] = useState(emptyError);
  const [frameError, setFrameError] = useState(emptyError);
  const [registrationError, setRegistrationError] = useState(emptyError);
  const [distanceError, setDistanceError] = useState(emptyError);
  const [customerEffectError, setCustomerEffectError] = useState(emptyError);
  const [diagnosticError, setDiagnosticError] = useState(emptyError);
  const [partsReplacedError, setPartsReplacedError] = useState(emptyError);
  const [partFrequency, setPartFrequency] = useState(null);

  useEffect(() => {
    if (configuration) {
        if (configuration.partFrequency) {
            setPartFrequency(configuration.partFrequency);
        }
      if (configuration.carType) {
        setCarType(configuration.carType);
      }
    }
  }, [configuration]);

  const hasErrors = () => {
    return (
      frameError.error ||
      carTypeError.error ||
      distanceError.error ||
      registrationError.error ||
      customerEffectError.error ||
      diagnosticError.error ||
      partsReplacedError.error
    );
  };

  const handleClick = () => {
    let hasError = false;
    
    if (values.car.frame === "") {
      setFrameError({
        error: true,
        msg: "RequiredFieldFrameError",
      });
      hasError = true;
    }
    
    if (values.car.distance === "") {
      setDistanceError({
        error: true,
        msg: "RequiredFieldDistanceError",
      });
      hasError = true;
    }

    if (values.car.registration === "") {
      setRegistrationError({
        error: true,
        msg: "RequiredFieldRegistrationError",
      });
      hasError = true;
    }

    if (values.car.customer_effect === "") {
      setCustomerEffectError({
        error: true,
        msg: "RequiredFieldCustomerEffectError",
      });
      hasError = true;
    }

    if (values.car.diagnostic === "") {
      setDiagnosticError({
        error: true,
        msg: "RequiredFieldDiagnosticError",
      });
      hasError = true;
    }

    if (values.car.parts_replaced === "") {
      setPartsReplacedError({
        error: true,
        msg: "RequiredFieldPartsReplacedError",
      });
      hasError = true;
    }

    if (values.car.carType.id === "" || values.car.carType.id === null) {
      setCarTypeError({
        error: true,
        msg: "RequiredFieldCarTypeError",
      });
      hasError = true;
    }

    if (!hasError) {
      nextStep();
    }
  };
  return (
    <>
      <Typography variant="h4" className={classes.title} align="center">
        {t("YourBrokenCar")}
      </Typography>
      <Typography
        variant="subtitle1"
        className={classes.titleDesc}
        align="center"
      >
        {t("YourBrokenCarDesc")}
      </Typography>
      <CotroliaStepper steps={steps} activeStep={activeStep} />

      <ContentTwoSided>
        <LeftSide>
          {/* Frame */}
         <CotroliaFormControl error={frameError.error}>
            <CotroliaTextField
              id="frame"
              label={t("Frame")}
              inputProps={{ maxLength: 17 }}
              maxLength={5}
              value={values.car.frame}
              onChange={(e) => {
                if (e.target.value !== "" && e.target.value.length === 17) {
                  setFrameError(emptyError);
                } else {
                  setFrameError({
                    error: true,
                    msg: "RequiredFieldFrameError",
                  });
                }
                updateValue("car", "frame", e.target.value);
              }}
              required
              rows={1}
              error={frameError.error}
            />
          </CotroliaFormControl>

          {/* Car type */}
          <CotroliaFormControl error={carTypeError.error}>
            <CotroliaAutocompleteCustom
              id="car-type-label"
              label={t("CarType")}
              value={values.car.carType}
              error={carTypeError.error}
              setValue={(newValue) => {
                if (newValue && newValue.id !== undefined) {
                  setCarTypeError(emptyError);
                  updateValue("car", "carType", newValue);
                } else {
                  setCarTypeError({
                    error: false,
                    msg: "RequiredFieldCarTypeError",
                  });
                  updateValue("car", "carType", {
                    id: null,
                    brand: newValue,
                    model: newValue,
                  });
                }
              }}
              getSuggestions={(value) =>
                configurationService
                  .getNestedDictionary("c_supercotrolia_brand_model", "label", value)
                  .then((res) => {
                    let ret = [];
                    Object.keys(res).forEach((key) => {
                      ret.push({ id: key, brand: res[key].label, model: res[key].datas });
                    });
                    return ret;
                  })
              }
              required
              isSuggestionObject
              labelKey="brand"
              valueKey="model"

            />
            <FormHelperText variant="outlined">
              {t(carTypeError.msg)}
            </FormHelperText>
          </CotroliaFormControl>

          {/* Distance */}
          <CotroliaFormControl error={distanceError.error}>
            <CotroliaTextField
              id="distance"
              label={t("Distance")}
              value={values.car.distance}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setDistanceError(emptyError);
                } else {
                  setDistanceError({
                    error: true,
                    msg: "RequiredFieldDistanceError",
                  });
                }
                updateValue("car", "distance", e.target.value);
              }}
              required
              type={"number"}
              error={distanceError.error}
            />
          </CotroliaFormControl>

          {/* Registration */}
          <CotroliaFormControl error={registrationError.error}>
            <CotroliaTextField
              id="registration"
              label={t("Registration")}
              value={values.car.registration}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setRegistrationError(emptyError);
                } else {  
                  setRegistrationError({
                    error: true,
                    msg: "RegisterErrorMissingRequiredFields",
                  });
                }
                
                updateValue("car", "registration", e.target.value);
              }}
              required
              error={registrationError.error}
            />
          </CotroliaFormControl>

          {/* Security code */}
            <CotroliaFormControl>
              <CotroliaTextField
                id="securitycode"
                label={t("SecurityCodeCotrolia")}
                value={values.car.security_code}
                onChange={(e) => { updateValue("car", "security_code", e.target.value); }}
              />
            </CotroliaFormControl>
        </LeftSide>

        <RightSide>
         {/* Frequency */}
         <CotroliaFormControl variant="filled">
             <CotroliaInputLabel id="frequency-label">
                 {t("PartFrequency")}
             </CotroliaInputLabel>
             <CotroliaSelect
                 id="frequency"
                 labelId="frequency-label"
                 value={values.part.frequency}
                 onChange={(e) => {
                     updateValue("part", "frequency", e.target.value);
                 }}
             >
                 {partFrequency &&
                     Object.keys(partFrequency).map((key) => (
                         <MenuItem value={key} key={key}>
                             {partFrequency[key]}
                         </MenuItem>
                     ))}
             </CotroliaSelect>
          </CotroliaFormControl>
          
          
          {/* Customer Effect */}
          {(configuration.sector == "1" &&
            <CotroliaFormControl error={customerEffectError.error}>
              <CotroliaTextField
                id="customer_effect"
                label={t("CarCustomerEffectCotrolia")}
                value={values.car.customer_effect}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    setCustomerEffectError(emptyError);
                  } else {
                    setCustomerEffectError({
                      error: true,
                      msg: "RequiredFieldCustomerEffectError",
                    });
                  }
                  updateValue("car", "customer_effect", e.target.value);
                }}
                required
                multiline
                rows={4}
                error={customerEffectError.error}
              />
            </CotroliaFormControl>
          )}

          {/* Diagnostic */}
            <CotroliaFormControl error={diagnosticError.error}>
              <CotroliaTextField
                id="diagnostic"
                label={t("DiagnosticCotrolia")}
                value={values.car.diagnostic}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    setDiagnosticError(emptyError);
                  } else {
                    setDiagnosticError({
                      error: true,
                      msg: "RequiredFieldDiagnosticError",
                    });
                  }
                  updateValue("car", "diagnostic", e.target.value);
                }}
                required
                multiline
                rows={4}
                error={diagnosticError.error}
              />
            </CotroliaFormControl>

          {/* Parts Replaced */}
          <CotroliaFormControl error={partsReplacedError.error}>
            <CotroliaTextField
              id="parts-replaced"
              label={t("PartsReplaced")}
              value={values.car.parts_replaced}
              onChange={(e) => {
                if (e.target.value !== "") {
                  setPartsReplacedError(emptyError);
                } else {
                  setPartsReplacedError({
                    error: true,
                    msg: "RequiredFieldPartsReplacedError",
                  });
                }
                updateValue("car", "parts_replaced", e.target.value);
              }}
              required
              multiline
              rows={4}
              error={partsReplacedError.error}
            />
          </CotroliaFormControl> 
          
        </RightSide>
      </ContentTwoSided>
      
      <CotroliaStepperActions>
        
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            previousStep();
          }}
          
          disabled={steps[activeStep] === steps[0]} // We disable the back button if we are in the first step
        >
          {t("GoBack")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          onClick={handleClick}
          disabled={hasErrors()}
        >
          {t("Continue")}
        </Button>
      </CotroliaStepperActions>
    </>
  );
};

export default NewInterventionStepCar;

